@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-image: url(https://github.com/TKDKid1000/tkdkid1000-website/blob/main/public/img/background.png?raw=true);
}

@layer components {
    .terminal-top {
        @apply bg-gray-600 h-7 rounded-t-md;
        .terminal-circle {
            @apply inline mr-2 first:ml-4 rounded-full w-3 h-3;
        }
    }
    .terminal {
        @apply bg-gray-900 p-2 h-96 text-white font-mono rounded-b-md;
        .terminal-line {
            .terminal-user {
                @apply text-green-600 inline;
            }
            .terminal-text {
                @apply inline whitespace-pre-wrap;
            }
            .terminal-cursor {
                @apply inline-block w-3 animate-[blink_1s_linear_infinite] bg-white;
            }
            .terminal-suggestion {
                @apply inline-block mx-2 focus:bg-white focus:text-black hover:bg-white hover:text-black;
            }
        }
    }
}
